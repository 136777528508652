<template>
  <div>
    <Loader full :visible="isLoading" />
    <div v-if="!isLoading && category">
      <h3 class="mb-3">{{ category[`name_${$i18n.locale}`] }}</h3>
      <b-row v-if="categoriesNews.length > 0">
        <b-col cols="12" class="my-3">
          <card-item
            :item="categoriesNews[0]"
            size="vertical-md"
            has-description
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="my-3"
          v-for="news in categoriesNews.slice(1)"
          :key="news.id"
        >
          <card-item :item="news" />
        </b-col>
      </b-row>
      <div v-else>{{ $t('not_found') }}</div>
      <loader :size="60" :visible="true" v-if="isFetching" />
      <button
        class="button button--default button--block mt-4"
        v-else-if="newsCount > currentPage * perCount && !isFetching"
        @click="uploadMore"
      >
        <span>
          {{ $t('upload_news') }}
        </span>
      </button>
    </div>
    <div v-else-if="!isLoading">
      <b-row>
        <b-col cols="12" md="5" data-aos="fade-up">
          <h2 class="mb-4">{{ $t('404_error') }}</h2>
          <p class="mb-4 not-found-desc">
            {{ $t('404_desc') }}
          </p>
          <router-link
            class="button button--default button--block"
            :to="'/' + $i18n.locale"
          >
            {{ $t('return_to_main') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CardItem from '@/components/cards/CardItem'
import Loader from '@/components/Loader'

export default {
  components: {
    CardItem,
    Loader
  },
  data: () => ({
    categorySlug: '',
    isLoading: false,
    isFetching: false,
    perCount: 21,
    currentPage: 1
  }),
  computed: {
    categoriesNews() {
      return this.$store.state.newsModule.news
    },
    newsCount() {
      return this.$store.state.newsModule.newsCount
    },
    category() {
      return this.$store.state.newsModule.category
    }
  },
  methods: {
    async uploadMore() {
      if (!this.isFetching) {
        this.isFetching = true
        this.currentPage++
        await this.fetchNews()
        this.isFetching = false
      }
    },
    async fetchNews() {
      await this.$store.dispatch('fetchNews', {
        _sort: 'publish_at:desc',
        'category.slug': this.categorySlug,
        _limit: this.perCount * this.currentPage
      })
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.isLoading = true
        this.categorySlug = this.$route.params.slug
        await this.$store.dispatch('fetchCategory', this.categorySlug)
        await this.fetchNews()
        await this.$store.dispatch('fetchNewsCount', {
          'category.slug': this.categorySlug
        })
        this.isLoading = false
      }
    }
  }
}
</script>
